import Vue from 'vue'
import VueSignature from "vue-signature-pad";
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from './router'
import store from './store'
import Snackbar from './plugins/snackbar'; // Assicurati che il percorso sia corretto
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.config.productionTip = false
Vue.use(VueSignature);

/*
const snackbar = new Vue({
  render: (h) => h(Snackbar),
}).$mount();

Vue.use(Snackbar, { snackbar }); // Passa il riferimento del componente Snackbar
*/

Vue.use(Snackbar);

store.dispatch('auth/autoLogin');

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')

