<template>
  <v-snackbar top v-model="visible" :timeout="2500" multi-line>
    <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
    {{ message }}
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      message: '',
    };
  },
  methods: {
    show(message) {
      this.message = message;
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>
