<template>
  <v-app id="inspire">
    <v-card elevation="2" shaped tile>
      <v-container>
        <v-row align="center" class="fixed-header">
          <!-- Colonna sinistra -->
          <v-col cols="auto" class="pa-0 d-flex justify-start">
            <v-btn icon @click="removeOneDay">
              <v-icon>mdi-chevron-left-box</v-icon>
            </v-btn>
            <v-btn icon @click="addOneDay">
              <v-icon>mdi-chevron-right-box</v-icon>
            </v-btn>
          </v-col>

          <!-- Colonna centrale (espandibile) -->
          <v-col class="pa-0">
            <v-menu 
              v-model="menuData" 
              :close-on-content-click="false"
              content-class="date-picker-menu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  @input="handleDateChange"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                @input="handleDateChange"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <!-- Colonna destra -->
          <v-col cols="auto" class="pa-0 d-flex justify-end">
            <v-btn class="add-button" fab @click="addItem">
              <v-icon>mdi-plus-box</v-icon>
            </v-btn>
          </v-col>
        </v-row>

<v-row 
  v-for="(item, index) in items" 
  :key="index" 
  :ref="'item-' + index" 
  :class="['demarcated-row', { highlighted: highlightedItemId === item.id }]"
  align="center"
  dense
  no-gutters
> 
  <v-col cols="12" sm="3" class="px-1">
    <v-autocomplete
      v-model="item.paziente"
      :items="pazienti"
      item-value="id"
      item-text="fullName"
      label="Paziente"
      prepend-icon="mdi-account"
      @change="onPazienteChange(index, item.paziente)"
    ></v-autocomplete>
  </v-col>

  <v-col cols="12" sm="2" class="px-1">
    <v-chip
      v-for="(terapista, tIndex) in item.terapisti"
      :key="tIndex"
      class="ma-2"
      color="teal" 
      outlined
      x-small
    >
      <v-avatar left>
        <v-icon>mdi-account-circle</v-icon>
      </v-avatar>
      {{ terapista.nome }} {{ terapista.cognome }}
    </v-chip>
  </v-col>

  <v-col cols="12" sm="2" class="px-1">
    <v-text-field
      v-mask="'##:##'"
      v-model="item.oraInizio"
      label="Ora Inizio"
      prepend-icon="mdi-timer"
      :rules="[() => validateOraStart(item)]"
    ></v-text-field>
  </v-col>

  <v-col cols="12" sm="2" class="px-1">
    <v-text-field
      v-mask="'##:##'"
      v-model="item.oraFine"
      label="Ora Fine"
      prepend-icon="mdi-timer-off"
      :rules="[() => validateOraEnd(item)]"
    ></v-text-field>
  </v-col>

  <!-- Aggiunta della checkbox o radio button per Supervisione o Fisioterapia -->
  <v-col cols="12" sm="2" class="px-1">
    <v-radio-group v-model="item.tipoTrattamento" row>
      <v-radio label="Terapia" value="TERAPIA"></v-radio>
      <v-radio label="Supervisione" value="SUPERVISIONE"></v-radio>
      <v-radio label="Longopedista" value="LONGOPEDISTA"></v-radio>
      
    </v-radio-group>
  </v-col>

  <v-col cols="12" sm="1" class="px-1">
    <v-btn icon @click="saveItem(index)">
      <v-icon>mdi-content-save</v-icon>
    </v-btn>
    <v-btn icon @click="removeItem(index)">
      <v-icon>mdi-delete</v-icon>
    </v-btn>
  </v-col>
</v-row>




      </v-container>
    </v-card>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {mask} from 'vue-the-mask'

export default {
  directives: {mask},
  props: {
    data: {
      type: String,
      default: () => new Date().toISOString().substr(0, 10), // Imposta la data corrente come default
    }
  },
  data() {
    return {
      highlightedItemId: null,
      menuData: false,
      //date: new Date().toISOString().substr(0, 10),
      date: this.data,
      items: [{ paziente: null, oraInizio: '', oraFine: '', terapisti: [], tipoTrattamento: 'TERAPIA' }],
    };
  },
  computed: {
    ...mapGetters('dailyTimesheet', ['allTimesheets']),
    ...mapGetters('paziente', ['allPazienti']),
    ...mapGetters('auth', ['getUser']),
    pazienti() {
      return this.allPazienti;
    },
    timesheets() {
      return this.allTimesheets;
    },
  },
  methods: {
    ...mapActions('dailyTimesheet', ['saveTimesheet', 'deleteTimesheet']),
    ...mapActions('paziente', ['fetchPazienti']),
    ...mapActions('auth', ['fetchUser']), // Assicurati che l'azione per recuperare l'utente sia presente

    addOneDay() {
      const currentDate = new Date(this.date);
      currentDate.setDate(currentDate.getDate() + 1);
      this.date = currentDate.toISOString().substr(0, 10);
      this.handleDateChange(this.date);
    },

    removeOneDay() {
      const currentDate = new Date(this.date);
      currentDate.setDate(currentDate.getDate() - 1);
      this.date = currentDate.toISOString().substr(0, 10);
      this.handleDateChange(this.date);
    },

    addItem() {
      const loggedInTerapista = this.$store.getters['auth/getUser'];
      
      this.items.push({
        id: null,
        paziente: null,
        oraInizio: '',
        oraFine: '',
        terapisti: loggedInTerapista ? [loggedInTerapista] : [], // Aggiungi il terapista loggato
        tipoTrattamento: 'TERAPIA'
      });

      // Attendi il prossimo ciclo di rendering per assicurarti che l'item sia stato aggiunto al DOM
      this.$nextTick(() => {
        const index = this.items.length - 1; // Ottieni l'indice dell'ultimo item
        const newItemElement = this.$refs['item-' + index][0]; // Accedi al riferimento dell'item
        
        // Scrolla fino all'item appena aggiunto
        if (newItemElement) {
          newItemElement.scrollIntoView({ behavior: 'smooth' });
        }

         // Evidenzia temporaneamente l'item appena aggiunto
        this.highlightedItemId = newItemElement.id;

        // Rimuovi l'evidenziazione dopo 2 secondi
        setTimeout(() => {
          this.highlightedItemId = null;
        }, 2000); // 2 secondi

      });
    },

    removeItem(index) {
      console.log("removeItem",this.items[index].id)
      if (this.items[index].id != null) {
        this.removeOneItem(index);
      }
      this.items.splice(index, 1); 
      
    },

    handleDateChange(date) {
      this.date = date;
      this.fetchTimesheets();
    },

    fetchTimesheets() {
      const loggedInTerapista = this.$store.getters['auth/getUser'];
      console.log("Terapista logged in: "+JSON.stringify(loggedInTerapista.username));
      this.$store.dispatch('dailyTimesheet/getTimesheetsByDate', { date: this.date, username: loggedInTerapista.username })
        .then(() => {
          this.items = this.timesheets.map((item) => {
            return {
              ...item, // Mantieni tutte le altre proprietà
              oraInizio: this.formatTime(item.oraInizio),
              oraFine: this.formatTime(item.oraFine),
            }});
        })
        .catch(error => {
          this.$snackbar('Errore caricamento per il '+this.date);
          console.error('Errore durante il recupero dei timesheets:', error);
        });
    },

    formatTime(timeString) {
      return timeString.substring(0, 5); // Ottieni solo HH:MM
    },

    async saveItem(index) {
        try {
          const loggedInTerapista = this.$store.getters['auth/getUser'];
          const itemToSave = this.items[index];
          console.log(JSON.stringify(itemToSave))
          
          // Verifica che il paziente sia presente
          if (!itemToSave.paziente) {
             //throw new Error('Paziente non selezionato');
            this.$snackbar('Errore: paziente non selezionato');
            return
          }

          if (!itemToSave.oraInizio) {
             //throw new Error('Paziente non selezionato');
            this.$snackbar('Errore: Ora Inzio non impostata');
            return
          }

          if (!itemToSave.oraFine) {
             //throw new Error('Paziente non selezionato');
            this.$snackbar('Errore: Ora Fine non impostata');
            return
          }

          var paziente = null;
          if (!itemToSave.id) {
            paziente = itemToSave.paziente
          } else {
            paziente = itemToSave.paziente.id
          }

          // Richiama l'azione dello store per ottenere i terapisti aggiuntivi
          const terapistiAggiuntivi = await this.$store.dispatch('dailyTimesheet/fetchTerapisti', {
              pazienteId: paziente,
              data: this.date,
              oraInizio: itemToSave.oraInizio,
               oraFine: itemToSave.oraFine
          });

          // Crea l'oggetto da salvare
          const itemToSaveData = {
              id: itemToSave.id || null,
              paziente: paziente,
              terapisti: [...new Set([...itemToSave.terapisti.map(t => t.id), loggedInTerapista.id, ...terapistiAggiuntivi.map(t => t.id)])], // Terapisti esistenti + quelli ottenuti dall'API + il terapista loggato
              data: this.date,
              oraInizio: itemToSave.oraInizio,
              oraFine: itemToSave.oraFine,
              tipoTrattamento: itemToSave.tipoTrattamento
          };

          // Salva il timesheet
          await this.$store.dispatch('dailyTimesheet/saveTimesheet', { index: index, timesheet: itemToSaveData });
          this.fetchTimesheets();
          this.$snackbar('Dati salvati correttamente.');
        } catch (error) {
            console.error('Errore durante il salvataggio del timesheet:', error);
            this.$snackbar('Errore durante il salvataggio: '+error);
        }
    },

    async removeOneItem(index) {
      try {
        this.$snackbar('Timesheet per '+this.items[index].paziente.nome+' '+this.items[index].paziente.cognome+' eliminato con successo.');
        await this.$store.dispatch('dailyTimesheet/deleteTimesheet', this.items[index].id);
        
      } catch (error) {
        console.error('Errore durante l\'eliminazione del timesheet:', error);
        this.$snackbar('Errore durante l\'eliminazione del timesheet:'+ error);
      }
    },

    validateOraStart(item) {
      if (!item.oraInizio) return 'Ora di inizio è obbligatoria';
      if (item.oraFine && item.oraInizio >= item.oraFine) return 'Ora di inizio deve essere prima dell\'ora di fine';
      return true;
    },

    validateOraEnd(item) {
      if (!item.oraFine) return 'Ora di fine è obbligatoria';
      if (item.oraInizio && item.oraFine <= item.oraInizio) return 'Ora di fine deve essere dopo l\'ora di inizio';
      return true;
    },

    reset() {
      this.date = new Date().toISOString().substr(0, 10);
      this.items = [{ paziente: null, oraInizio: '', oraFine: '', terapisti: [] }];
    },

    onPazienteChange(index, paziente) {
      console.log('Paziente selezionato:', paziente);
      // Gestisci l'oggetto paziente selezionato qui
      this.items[index].paziente = paziente;
    }
  },

  mounted() {
    this.fetchPazienti();
    this.fetchTimesheets();
  }
};
</script>

<style scoped>
.fixed-header {
  margin-bottom: 16px;
}

.demarcated-row {
  margin-top: 10px;
  border-top: 1px solid rgb(112, 112, 112);
  padding-top: 10px;
}

/* Classe di evidenziazione */
.highlighted {
  background-color: #c8f5f5;
  transition: background-color 0.5s ease;
}

.v-date-picker {
  width: 290px;
}

.teal {
  color: teal;
}

.date-picker-menu {
  margin-top: 8px; /* Aggiungi un po' di spazio per separare il v-date-picker dal v-text-field */
  border-radius: 0; /* Rimuovi il bordo arrotondato se necessario */
  box-shadow: none; /* Rimuovi l'ombra per evitare conflitti visivi */
}

.add-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; /* Assicura che il pulsante sia sopra altri elementi */
}
</style>
