<template>
  <v-app id="inspire">
    <v-card elevation="2" shaped tile>
      <v-container>
        <v-row>
          <v-col class="pa-0" cols="12" md="12">
            <!-- Mostra la firma esistente, se presente -->
            <div v-if="signatureUrl && !isEditing">
              <img :src="signatureUrl" alt="Firma Terapista" width="100%" height="400px"/>
            </div>
            
            <!-- Mostra VueSignaturePad se si sta modificando -->
            <VueSignaturePad
              v-if="isEditing"
              id="signature"
              width="100%"
              height="400px"
              ref="signaturePad"
              :options="options"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" class="buttons">
            <!-- Pulsante per modificare o salvare -->
            <v-btn @click="isEditing ? saveSignature() : startEditing()" color="success">
              {{ isEditing ? "Salva" : "Modifica" }}
            </v-btn>
            
            <!-- Mostra il pulsante Reset solo se si sta modificando -->
            <v-btn v-if="isEditing" @click="undo" color="primary">Reset</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-app>
</template>


<script>
import axios from 'axios';

export default {
  name: "App",
  data: () => ({
    options: {
 dotSize: 3,   // Imposta la dimensione del tratto
      minWidth: 1,              // Imposta la larghezza minima del tratto
      maxWidth: 5,              // Imposta la larghezza massima del tratto
      throttle: 32,               // Controlla la frequenza di aggiornamento
      minDistance: 5,             // Distanza minima tra i punti per disegnare una linea
      backgroundColor: 'rgba(0,0,0,0)', // Colore dello sfondo (trasparente)
      penColor: 'black',          // Colore del tratto (nero)
      velocityFilterWeight: 0.7,  // Peso del filtro di velocità per il tratto
    },
    signatureUrl: null,
    isEditing:false,
  }),
  methods: {
    undo() {
      this.$refs.signaturePad.clearSignature();
    },

    startEditing() {
      this.isEditing = true;
      this.$refs.signaturePad.clearSignature(); // Resetta la firma corrente per modificarla
    },

    // Funzione per convertire data URL in Blob
    dataURLtoBlob(dataURL) {
      const byteString = atob(dataURL.split(",")[1]);
      const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ab], { type: mimeString });
    },

    async loadSignature() {
      const terapistaId = this.$store.getters['auth/getUser'].id;
      try {
        const response = await axios.get(`/api/terapista/${terapistaId}/signature`, {
          responseType: 'blob' // Ottieni il file come blob
        });

        if (response.status === 200) {
          // Crea un URL temporaneo per visualizzare l'immagine
          this.signatureUrl = URL.createObjectURL(response.data);
        }
      } catch (error) {
        console.error("Errore nel caricamento della firma:", error);
      }
    },

    async saveSignature() {
      const terapistaId = this.$store.getters['auth/getUser'].id;
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$snackbar("Non è stata disegnata nessuna firma.");
        return;
      }

      // Converti la firma in un Blob
      const signatureBlob = this.dataURLtoBlob(data);

      // Crea un oggetto FormData per inviare il file
      const formData = new FormData();
      formData.append("signature", signatureBlob, "firma.png");

      try {
        // Invia il file tramite una richiesta POST
        const response = await axios.post(`/api/terapista/${terapistaId}/signature`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.$snackbar(response.data);

        // Carica la nuova firma
        this.loadSignature();

        // Disabilita la modalità modifica
        this.isEditing = false;

      } catch (error) {
        console.error("Errore durante il salvataggio della firma:", error);
        this.$snackbar("Si è verificato un errore durante il salvataggio della firma.");
      }
    },
  },

  mounted() {
    // Carica la firma esistente quando il componente viene montato
    this.loadSignature();
  }

};
</script>

<style>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
  color: hwb(0 0% 100%);
}
</style>
