<template>
    <v-app id="inspire">
      <v-card elevation="2" shaped tile>
        <v-container>
          <!-- Selezione del mese con i pulsanti per scorrere -->
          <v-row align="center">
            <v-col cols="auto" class="pa-0  d-flex">
              <v-btn icon @click="removeOneMonth" >
                <v-icon color="grey">mdi-chevron-left-box</v-icon>
              </v-btn>
              <v-btn icon @click="addOneMonth">
                <v-icon color="grey">mdi-chevron-right-box</v-icon>
              </v-btn>
            </v-col>
            <v-col >
              <v-select
                v-model="selectedMonth"
                :items="months"
                label="Seleziona mese"
                @change="fetchMonthlyReport"
              ></v-select>
            </v-col>
            <v-col cols="auto" class="pa-0  d-flex">
              <v-btn icon @click="downloadReport" label="Download">
                <v-icon>mdi-file-download</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto" class="pa-0  d-flex">
              <v-btn icon @click="downloadReport" label="Download">
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <div class="legend">
                <v-chip class="lightblue" label>
                  Longopedista
                </v-chip>
                <v-chip class="lightgreen" label>
                  Supervisione
                </v-chip>
              </div>
            </v-col>
          </v-row>

          <!-- Elenco dei report mensili -->
          <v-row>
            <v-col v-for="report in monthlyReports" :key="report.pazienteNome + report.pazienteCognome">
              <v-card elevation="2" outlined tile>
                  <!--
                  <v-card-title>
                  <v-row align="center" justify="space-between" style="width: 100%;">
                    <v-col>
                      <div>
                        {{ report.pazienteNome }} {{ report.pazienteCognome }}
                      </div>
                      <v-card-subtitle :class="getSubtitleClass(report)">
                        Ore Totali: {{ report.oreTotali }} di {{ report.oreTerapia }}
                      </v-card-subtitle>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn icon @click="downloadSignatureReport(report)">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>
                -->
               <!--  
                <v-card-title>
                  <v-icon class="prepend-icon">mdi-download</v-icon>
                  {{report.pazienteId}} {{ report.pazienteNome }} {{ report.pazienteCognome }} 
                  <v-btn icon>
                  </v-btn>
                </v-card-title>
                <v-card-subtitle :class="getSubtitleClass(report)">
                  Ore Totali: {{ report.oreTotali }} di {{ report.oreTerapia }}
                </v-card-subtitle>
                -->
                <v-card-title>
                  <span>{{ report.pazienteNome }} {{ report.pazienteCognome }}</span>
                  <v-btn absolute right top icon @click="downloadSignatureReport(report)">
                      <v-icon>mdi-download</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-subtitle :class="getSubtitleClass(report)">
                  Ore Totali: {{ report.oreTotali }} di {{ report.oreTerapia }}
                </v-card-subtitle>
                <v-card-text>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        v-for="detail in report.dettagliGiornalieri"
                        :key="`${report.pazienteNome}-${report.pazienteCognome}-${detail.data}`"
                        @click="goToDate(detail.data)"
                      >
                        <!-- Layout flessibile per data e orario -->
                        <div class="d-flex justify-space-between align-center" 
                        :class="getTrattamentoClass(detail.tipoTrattamento)">
                          <!-- Icona del calendario e giorno -->
                          <div class="d-flex align-center">
                            <v-icon color="grey">mdi-calendar-today</v-icon>
                            <span>{{ formatDate(detail.data) }}</span>
                          </div>
                          <!-- Icona del timer e orari -->
                          <div class="d-flex align-center">
                            <v-icon color="grey">mdi-timer</v-icon>
                            <span>
                              {{formatTime(detail.oraInizio) }} - {{ formatTime(detail.oraFine) }}
                            </span>
                          </div>
                        </div>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
  </v-app>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      monthlyReports: [],
      selectedMonth: new Date().toISOString().slice(0, 7), // Mese corrente in formato YYYY-MM
      months: this.getMonths(),
    };
  },
  methods: {
    goToDate(date) {
      console.log("prima di passarla: ", date)
      this.$router.push({ name: 'DailyTimesheet', params: { data: date } });
    },
    getMonths() {
      const months = [];
      const date = new Date();
      for (let i = 0; i < 13; i++) {
        var value = new Date(date.getFullYear(), date.getMonth() - i + 1)
          .toISOString()
          .slice(0, 7);
        var text = new Date(date.getFullYear(), date.getMonth() - i).toLocaleString('default', {
          month: 'long',
          year: 'numeric',
        });
        months.push({
          value: value,
          text: text,
        });
      }
      return months;
    },
    formatDate(date) {
      // Formatta la data come "giorno mese anno"
      return new Date(date).toLocaleDateString('it-IT', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      });
    },
    formatTime(time) {
      // Mostra solo le ore e minuti nel formato HH:mm
      return time.slice(0, 5);
    },
    fetchMonthlyReport() {
      const [year, month] = this.selectedMonth.split("-");
      const monthForBackend = parseInt(month); // month è già nel formato corretto
      const terapistaId = this.$store.getters['auth/getUser'].id;

      axios
        .get(`/api/timesheets/monthly-report?terapistaId=${terapistaId}&mese=${monthForBackend}&anno=${parseInt(year)}`)
        .then((response) => {
          this.monthlyReports = response.data;
        }).catch((error) => {
          console.error("Errore nel recupero del report:", error);
          this.$snackbar("Errore nel recupero del report: "+ error);
        });
    },
    removeOneMonth() {
      const currentDate = new Date(this.selectedMonth);
      currentDate.setMonth(currentDate.getMonth() - 1);
      this.selectedMonth = currentDate.toISOString().slice(0, 7);
      this.fetchMonthlyReport();
    },


    addOneMonth() {
      const currentDate = new Date(this.selectedMonth);
      const nextMonth = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
      const today = new Date();

      if (nextMonth > today) {
        return; // Evita di andare avanti oltre il mese corrente
      }

      this.selectedMonth = nextMonth.toISOString().slice(0, 7);
      this.fetchMonthlyReport();
    },

    getSubtitleClass(report) {
      // Converti i valori in numeri per il confronto
      return {
        'text-red': parseFloat(report.oreTotali) > parseFloat(report.oreTerapia),
      };
    },

    getTrattamentoClass(tipoTrattamento) {
      if (tipoTrattamento === 'LONGOPEDISTA') {
        return 'lightblue';
      } else if (tipoTrattamento === 'SUPERVISIONE') {
        return 'lightgreen';
      } else {
        return ''; // Nessuna classe se non è uno di questi tipi
      }
    },

    async downloadReport() {
      try {
        const terapista = this.$store.getters['auth/getUser'];
        const [year, month] = this.selectedMonth.split("-");
        const monthForBackend = parseInt(month);

        this.$snackbar('Avvio download Foglio ore mensile ('+monthForBackend+' '+year+')...');
        const response = await axios.get(`/api/reports/timesheetReport`, {
          params: {
            terapistaId: terapista.id,
            mese: monthForBackend,
            anno: parseInt(year)
          },
          responseType: 'blob', // Indica che la risposta è un file binario (PDF)
        });

        // Crea un URL oggetto dal file Blob
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        // Imposta il nome del file (puoi cambiarlo in base alle tue esigenze)
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `report_${terapista.username}_${monthForBackend}_${year}.pdf`);

        // Aggiungi il link al DOM temporaneamente e fai clic su di esso
        document.body.appendChild(fileLink);
        fileLink.click();

        // Rimuovi il link dopo il download
        document.body.removeChild(fileLink);
        } catch (error) {
          console.error("Errore nel download del report:", error);
          this.$snackbar('Errore nel download del report: '+error);
        }
      },

      async downloadSignatureReport(report) {
        try {
          this.$snackbar('Avvio download Foglio firme... ');
          const [year, month] = this.selectedMonth.split("-");
          const pazienteId = report.pazienteId;
          const monthForBackend = parseInt(month);

          // Effettua la richiesta GET per il report delle firme
          const response = await axios.get(`/api/reports/signatureReport/${pazienteId}/${monthForBackend}/${parseInt(year)}`, {
            responseType: 'blob', // Indica che la risposta sarà un file binario (PDF)
          });

          // Crea un URL oggetto dal file Blob
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');

          // Imposta il nome del file (puoi personalizzarlo in base alle tue esigenze)
          const fileName = `signatures_${report.pazienteCognome}_${monthForBackend}_${year}.pdf`;
          fileLink.href = fileURL;
          fileLink.setAttribute('download', fileName);

          // Aggiungi il link al DOM e simulare il clic
          document.body.appendChild(fileLink);
          fileLink.click();

          // Rimuovi il link dopo il download
          document.body.removeChild(fileLink);
        } catch (error) {
          this.$snackbar('Errore nel download del Foglio Firme: '+error);
          console.error("Errore nel download del report delle firme:", error);
        }
      }

  },
  mounted() {
    this.fetchMonthlyReport();
  },
};
</script>

<style scoped>
/* Stile per cambiare colore al testo in rosso */
.text-red {
  color: red;
}

.lightblue {
  background-color: lightblue !important; /* Colore di sfondo blu chiaro */
  color: black !important; /* Colore del testo */
}

.lightgreen {
  background-color: lightgreen !important; /* Colore di sfondo verde chiaro */
  color: black !important; /* Colore del testo */
}

.legend {
  display: flex;
  gap: 10px; /* Spaziatura tra i chip */
  margin-bottom: 20px; /* Margine inferiore */
}

</style>
