let snackbarInstance = null; // Variabile per memorizzare l'istanza della Snackbar

export default {
    install(Vue) {
        // Aggiungi il metodo per mostrare la snackbar
        Vue.prototype.$snackbar = (message) => {
            if (snackbarInstance) {
                snackbarInstance.show(message);
            } else {
                console.warn('Snackbar instance is not available.');
            }
        };

        // Registrare il riferimento nel mixin di Vue
        Vue.mixin({
            mounted() {
                if (this.$refs.snackbar) {
                    snackbarInstance = this.$refs.snackbar; // Memorizza il riferimento
                }
            },
        });
    },
};
