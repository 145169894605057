// src/axios.js
import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://timesheet.digitalbridge.srl/api', // URL di base per le API
  //baseURL: 'http://localhost:7785/api'
  // Altre configurazioni globali se necessarie
});

export default instance;
