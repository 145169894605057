<template>
  <v-app id="inspire" color="teal">
    <v-app-bar v-if="!hideSidebar" app color="teal" dark>
      <!-- Menu Drawer Icon -->
      <v-app-bar-nav-icon v-if="!hideSidebar" @click="drawer = !drawer"></v-app-bar-nav-icon>

      <!-- Titolo della pagina posizionato sulla sinistra -->
      <v-toolbar-title v-if="!hideSidebar" class="page-title">{{ pageTitle }}</v-toolbar-title>

      <!-- Spaziatore per allineare a destra il saluto e il pulsante di logout -->
      <v-spacer></v-spacer>

      <!-- Se l'utente è autenticato, mostra il saluto e il pulsante di logout sulla destra -->
      <div v-if="isAuthenticated" class="d-flex align-center">
        <!-- Saluto -->
        <!--<v-btn text class="white--text">Ciao, {{ userName }}</v-btn>-->
        <router-link :to="{ name: 'Profile' }">
          <v-btn text class="white--text">Ciao, {{ userName }}</v-btn>
        </router-link>

        <!-- Logout -->
        <v-btn icon @click="logout" class="ml-3">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </div>
      
    </v-app-bar>

    <v-navigation-drawer
      app
      v-model="drawer"
      :temporary="$vuetify.breakpoint.mdAndDown"
      dark
      v-if="!hideSidebar"
    >
      <!-- Logo and Title Section -->
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img src="@/assets/logo.svg" alt="Logo" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              class="text-uppercase font-weight-bold"
              style="color: teal"
            >
              TIMESHEET APP
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <!-- Elenco dei link -->
      <v-list dense nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.link"
          link
          @click="setPageTitle(item.title)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view />
        <snackbar ref="snackbar" /> 
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from './components/Snackbar.vue';

export default {
  components: {
    Snackbar
  },  
  data() {
    return {
      drawer: false, // Stato del drawer (aperto o chiuso)
      pageTitle: 'Dashboard', // Titolo della pagina iniziale
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard', link: "/dashboard" },
        { title: 'Daily Timesheet', icon: 'mdi-table-large', link: "/dailytimesheet" },
        { title: 'Monthly Report', icon: 'mdi-file-chart', link: "/monthlyreport" }
      ]
    };
  },
  
  computed: {
    hideSidebar() {
      // Controlla se il meta campo 'layout' è 'none'
      return this.$route.meta.layout === 'none';
    },
    isAuthenticated() {
      // Verifica se l'utente è autenticato
      return this.$store.getters['auth/isAuthenticated'];
    },
    userName() {
      // Recupera il nome dell'utente dallo store Vuex
      const user = this.$store.getters['auth/getUser'];
      return user ? `${user.nome} ${user.cognome}` : '';
    }
  },
  watch: {
    $route(to) {
      // Aggiorna il titolo della pagina quando la route cambia
      this.pageTitle = to.meta.title || 'Default Title';
    }
  },
  
  methods: {
    //snackbarRef: true,
    showSnackbar(message) {
      this.$refs.snackbar.show(message);
    },
    // Metodo per impostare dinamicamente il titolo della pagina
    setPageTitle(title) {
      this.pageTitle = title;
    },
    // Metodo di logout
    logout() {
      this.$store.dispatch('auth/logoutUser');
      this.$router.push('/login'); // Reindirizza alla pagina di login dopo il logout
    }
  },
  mounted() {
    // Assicurati di memorizzare il riferimento dell'istanza nella variabile del plugin
    if (this.$refs.snackbar) {
      this.$snackbarInstance = this.$refs.snackbar; // Memorizza l'istanza
    }
  }
};
</script>

<style>
/* Aggiungi eventuali stili personalizzati */
</style>
